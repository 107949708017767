import React, { useEffect, useState, useRef } from 'react';
import './App.css';

function getMousePos(canvas, evt) {
  var rect = canvas.getBoundingClientRect();
  return {
    x: evt.clientX - rect.left,
    y: evt.clientY - rect.top
  };
}

//config

const DOGEIO_WIDTH = 80;
const DOGEIO_HEIGHT = 100;
let DOGEIO_SPEED = 3;
const DOGEIO_JUMP_SIZE = 80;
const DOGEIO_JUMP_SPEED = 2.5;

let DRAW_DOGEIO_LINE = false;
let VERBOSE_OUTPUT = false;

// utils
const DOGEIO_WIDTH_HALF = DOGEIO_WIDTH / 2;
const DOGEIO_HEIGHT_HALF = DOGEIO_HEIGHT / 2;

let screenWidth = window.innerWidth;
let screenHeight = window.innerHeight;


const padding = 20;
const headerSize = 80;
let canvasWidth = screenWidth - padding * 2;
let canvasHeight = screenHeight - padding * 2 - headerSize;
let logoWidth = Math.min(canvasWidth, canvasHeight) / 2;

let marioX = DOGEIO_WIDTH_HALF;
let marioY = DOGEIO_HEIGHT_HALF;
let marioD = 0;
let marioJump = 0;
// let marioJumpLeft = DOGEIO_JUMP_SIZE;
let marioJumpLeft = 0;
let marioImage = new Image();
marioImage.src = '/images/doge.png';
marioImage.width = DOGEIO_WIDTH;
marioImage.height = DOGEIO_HEIGHT;

const greeting1 = 'NICE';
const greeting2 = 'GREAT';
const greeting3 = 'SWEET';
const greeting4 = 'AMAZING';
const greetingMoon = 'TO THE MOON!';



let secondsPassed;
let oldTimeStamp;
let fps;

function App() {
  const canvasRef = useRef(null);

  useEffect(() => {
    const handleScreenResize = () => {
      screenWidth = window.innerWidth;
      screenHeight = window.innerHeight;
    }
    window.addEventListener('resize', handleScreenResize);

    // Start the first frame request
    window.requestAnimationFrame(gameLoop);

    return () => {
      window.removeEventListener('resize', handleScreenResize);
    };
  }, []);


  const moveForward = () => {
    if (marioD === 0) {
      if (marioX < canvasWidth - DOGEIO_HEIGHT_HALF) {
        marioX += DOGEIO_SPEED

        // jump
        if (marioJumpLeft > 0) {
          marioY += DOGEIO_JUMP_SPEED;
          marioJumpLeft -= Math.min(DOGEIO_JUMP_SPEED, marioJumpLeft);
        } else if (marioY > DOGEIO_HEIGHT_HALF) {
          marioY -= Math.min(DOGEIO_JUMP_SPEED, marioY + DOGEIO_HEIGHT_HALF);
        }

      } else {
        const jumpHeight = marioY - DOGEIO_HEIGHT_HALF
        marioX = canvasWidth - DOGEIO_HEIGHT_HALF - jumpHeight
        marioY = DOGEIO_HEIGHT_HALF
        marioD = 1
      }

      return;
    }

    if (marioD === 1) {
      if (marioY < canvasHeight - DOGEIO_HEIGHT_HALF) {
        marioY += DOGEIO_SPEED

        // jump
        if (marioJumpLeft > 0) {
          marioX -= DOGEIO_JUMP_SPEED;
          marioJumpLeft -= Math.min(DOGEIO_JUMP_SPEED, marioJumpLeft);
        } else if (marioX < canvasWidth - DOGEIO_HEIGHT_HALF) {
          marioX += Math.min(DOGEIO_JUMP_SPEED, marioX + DOGEIO_HEIGHT_HALF);
        }

        // // jump
        // if (marioJumpLeft > 0) {
        //   if (marioJumpLeft > DOGEIO_JUMP_SIZE / 2) {
        //     marioX -= DOGEIO_JUMP_SPEED;
        //   } else {
        //     marioX += Math.min(DOGEIO_JUMP_SPEED, marioX);
        //   }
        //   marioJumpLeft -= Math.min(DOGEIO_JUMP_SPEED, marioJumpLeft);
        // }

      } else {
        marioX = canvasWidth - DOGEIO_WIDTH_HALF
        marioY = canvasHeight - DOGEIO_HEIGHT_HALF - marioJumpLeft
        marioD = 2
      }

      return;
    }

    if (marioD === 2) {
      if (marioX > DOGEIO_HEIGHT_HALF) {
        marioX -= DOGEIO_SPEED


        // jump
        if (marioJumpLeft > 0) {
          marioY -= DOGEIO_JUMP_SPEED;
          marioJumpLeft -= Math.min(DOGEIO_JUMP_SPEED, marioJumpLeft);
        } else if (marioY < canvasHeight - DOGEIO_HEIGHT_HALF) {
          marioY += Math.min(DOGEIO_JUMP_SPEED, marioY + DOGEIO_HEIGHT_HALF);
        }


        // // jump
        // if (marioJumpLeft > 0) {
        //   if (marioJumpLeft > DOGEIO_JUMP_SIZE / 2) {
        //     marioY -= DOGEIO_JUMP_SPEED;
        //   } else {
        //     marioY += Math.min(DOGEIO_JUMP_SPEED, marioY);
        //   }
        //   marioJumpLeft -= Math.min(DOGEIO_JUMP_SPEED, marioJumpLeft);
        // }


      } else {
        marioX = DOGEIO_HEIGHT_HALF
        marioY = canvasHeight - DOGEIO_HEIGHT_HALF
        marioD = 3
      }

      return;
    }

    if (marioD === 3) {
      if (marioY > DOGEIO_HEIGHT_HALF) {
        marioY -= DOGEIO_SPEED

      // jump
      if (marioJumpLeft > 0) {
        marioX += DOGEIO_JUMP_SPEED;
        marioJumpLeft -= Math.min(DOGEIO_JUMP_SPEED, marioJumpLeft);
      } else if (marioX > DOGEIO_HEIGHT_HALF) {
        marioX -= Math.min(DOGEIO_JUMP_SPEED, marioX + DOGEIO_HEIGHT_HALF);
      }

        // // jump
        // if (marioJumpLeft > 0) {
        //   if (marioJumpLeft > DOGEIO_JUMP_SIZE / 2) {
        //     marioX += DOGEIO_JUMP_SPEED;
        //   } else {
        //     marioX -= Math.min(DOGEIO_JUMP_SPEED, marioX);
        //   }
        //   marioJumpLeft -= Math.min(DOGEIO_JUMP_SPEED, marioJumpLeft);
        // }

      } else {
        marioX = DOGEIO_WIDTH_HALF
        marioY = DOGEIO_HEIGHT_HALF
        marioD = 0
      }

      return;
    } 
  }

const drawDoge = (ctx) => {
  // var x = ctx.width / 2;
  // var y = ctx.height / 2;
  // var width = image.width;
  // var height = image.height;

  let angle = 0;
  if (marioD === 0) {
    angle = 0;
  }
  switch (marioD) {
    case 0:
      angle = Math.PI;
      break;
    case 1:
      angle = -Math.PI / 2;
      break;
    case 2:
      angle = 0;
      break;
    case 3:
      angle = Math.PI / 2;
  }

  ctx.translate(marioX, marioY);
  ctx.rotate(angle);
  // ctx.drawImage(image, -width / 2, -height / 2, width, height);
  ctx.drawImage(marioImage, - DOGEIO_WIDTH_HALF, - DOGEIO_HEIGHT_HALF, DOGEIO_WIDTH, DOGEIO_HEIGHT);
  ctx.rotate(-angle);
  ctx.translate(-marioX, -marioY);

  // ctx.drawImage(marioImage, marioX - DOGEIO_WIDTH_HALF, marioY - DOGEIO_HEIGHT_HALF, DOGEIO_WIDTH, DOGEIO_HEIGHT);
}
  

function gameLoop(timeStamp) {

  if (!canvasRef.current) {
    return;
  }

  const c = canvasRef.current;
  const ctx = c.getContext('2d');
  if (!ctx) {
    return
  }

  // clean screen
  const rect = c.getBoundingClientRect();
    ctx.beginPath();
    ctx.clearRect(0, 0, rect.width, rect.height);


    if (VERBOSE_OUTPUT) {

      // Calculate the number of seconds passed since the last frame
      secondsPassed = (timeStamp - oldTimeStamp) / 1000;
      oldTimeStamp = timeStamp;

      // Calculate fps
      fps = Math.round(1 / secondsPassed);

      // Draw number to the screen
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, 200, 100);
      ctx.font = '25px Arial';
      ctx.fillStyle = 'black';
      ctx.fillText("FPS: " + fps, 10, 30);
      ctx.font = '15px Arial';
      ctx.fillText(`jump: ${marioJumpLeft} x: ${marioX} y: ${marioY}`, 10, 60);

    }


    
    moveForward();

    // Perform the drawing operation
    

    if (DRAW_DOGEIO_LINE) {
      ctx.moveTo(0, 0);
      ctx.lineTo(marioX, marioY);
      ctx.stroke();
    }

    drawDoge(ctx);

    // The loop function has reached it's end. Keep requesting new frames
    window.requestAnimationFrame(gameLoop);
}


  const mouseEvent1 = (e) => {

    if (!canvasRef.current) {
      return;
    }
    const c = canvasRef.current;

    const m = getMousePos(c, e)

    const ctx = c.getContext('2d');
    if (ctx) {
      const rect = c.getBoundingClientRect();

      ctx.beginPath();
      ctx.clearRect(0, 0, rect.width, rect.height);

      ctx.strokeStyle = "White";

      ctx.moveTo(0, 0);
      ctx.lineTo(m.x, m.y);
      ctx.stroke();
      
      ctx.moveTo(rect.width, rect.height);
      ctx.lineTo(m.x, m.y);
      ctx.stroke();

      ctx.moveTo(rect.width, 0);
      ctx.lineTo(m.x, m.y);
      ctx.stroke();

      ctx.moveTo(0, rect.height);
      ctx.lineTo(m.x, m.y);
      ctx.stroke();
    }
  }

  const keyPress1 = (e) => {
    console.log(e.key)

    if (marioJumpLeft === 0) {
      marioJumpLeft = DOGEIO_JUMP_SIZE;
    }
  }

  const mousePress1 = (e) => {
    // console.log(e.key)

    if (marioJumpLeft === 0) {
      marioJumpLeft = DOGEIO_JUMP_SIZE;
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <div style={{ zIndex: 10 }} onMouseMove={mouseEvent1} onMouseDown={mousePress1}>
          <img src={'/images/dogecoin-logo.png'} className="App-logo" alt="logo" width={logoWidth} onMouseDown={mousePress1} />
        </div>
        <div style={{position: 'absolute', top: headerSize/2 - 20, fontFamily: "Comic Sans MS, Comic Sans, cursive", fontSize: 40}}>Dogeio</div>
        <canvas ref={canvasRef} style={{ border: '1px solid #000000', position: 'absolute', top: padding + headerSize, left: padding, opacity: 1 }} 
        // width={screenWidth - 10} 
        // height={screenHeight - 10}
        width={canvasWidth} 
        height={canvasHeight}
          // onClick={mouseEvent1}
          tabIndex={0}
          onMouseMove={mouseEvent1}
          onMouseDown={mousePress1}
          onKeyDown={keyPress1}
        />
      </header>
    </div>
  );
}

export default App;
